<template>
  <b-modal
    id="create-or-update-class-modal"
    v-model="$show"
    centered
    scrollable
    size="xl"
    header-class="modal__header"
    footer-class="modal__footer"
    body-class="modal__body custom-body"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <template #modal-header="{}">
      <h3 class="font-weight-bold">บันทึกการสอน</h3>
      <span v-if="editData && editData.updatedAt"
        >อัพเดตล่าสุด:
        {{ $_formatDateMixin_formatShort(editData.updatedAt) }}</span
      >
    </template>

    <div class="menu-list d-flex flex-row align-items-baseline">
      <button
        class="menu-item font-weight-bold ft-s-18 p-2"
        @click="scrollToSection('dataRef')"
      >
        ข้อมูลห้องเรียน
      </button>
      <button
        class="menu-item font-weight-bold ft-s-18 p-2"
        @click="scrollToSection('memberRef')"
      >
        รายชื่อ
      </button>
      <button
        class="menu-item font-weight-bold ft-s-18 p-2"
        @click="scrollToSection('classRef')"
      >
        เนื้อหาการสอน
      </button>
    </div>

    <div class="wrap-body w-100">
      <div ref="dataRef" class="d-flex flex-row align-items-baseline px-2">
        <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
        <h4 class="font-weight-bold ft-s-18">ข้อมูลห้องเรียน</h4>
      </div>

      <filter-master
        v-model="filterMaster"
        :m-headquarter-id="editData.mHeadquarterId"
        :m-division-id="editData.mDivisionId"
        :m-station-id="editData.mStationId"
        :m-school-id="editData.mSchoolId"
        :m-class-id="editData.mClassId"
        :disabled-input="{
          headQuarter: !authUser.isSuperAdmin || isSubmiting,
          division: !authUser.isSuperAdmin || isSubmiting,
          station: !authUser.isSuperAdmin || isSubmiting,
          schoolClass: (!authUser.isSuperAdmin && isPlanSuccess) || isSubmiting,
        }"
        col-headquarter="4"
        col-division="4"
        col-station="4"
        col-school-class="2"
        default-label="-"
        :hidden-input="{ school: true, room: true }"
      >
        <template #beforeSchoolClass>
          <b-col cols="10" class="px-0">
            <!-- <div class="d-flex flex-row align-items-baseline px-2">
            <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
            <h4 class="font-weight-bold ft-s-18">ข้อมูลห้องเรียน</h4>
          </div> -->

            <filter-province
              v-model="filterProvince"
              :m-province-id="editData.mProvinceId || null"
              :m-amphur-id="editData.mAmphurId || null"
              :m-school-id="editData.mSchoolId || null"
              :disabled-input="{
                province:
                  (!authUser.isSuperAdmin && isPlanSuccess) || isSubmiting,
                amphur:
                  (!authUser.isSuperAdmin && isPlanSuccess) || isSubmiting,
                school:
                  (!authUser.isSuperAdmin && isPlanSuccess) || isSubmiting,
              }"
              default-label="-"
            >
            </filter-province>
          </b-col>
        </template>

        <b-col cols="10" class="px-0">
          <teaching-dare-form
            v-model="formTeachingDare"
            :edit-data="editData"
            :isClass="false"
            :disabled="
              (!authUser.isSuperAdmin && isPlanSuccess) ||
              disabled ||
              isSubmiting
            "
          ></teaching-dare-form>
        </b-col>
      </filter-master>

      <div
        ref="memberRef"
        class="d-flex flex-row align-items-baseline px-2 my-2"
      >
        <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
        <h4 class="font-weight-bold ft-s-18">รายชื่อผู้อำนวยการโรงเรียน</h4>
      </div>
      <div v-if="formTeachingDare && formTeachingDare.director_dares[0]">
        <div
          v-for="(director, index) in formTeachingDare.director_dares"
          :key="`director__${index}`"
          class="d-flex flex-row flex-wrap"
        >
          <div class="col-lg-2 col-12 px-2 mb-2">
            <label class="font-weight-bold ft-s-14" for="box5"
              >คำนำหน้าชื่อ</label
            >
            <!-- <v-select
          v-model="director.mPrefixId"
          :options="prefixes"
          :reduce="(prefixes) => prefixes.id"
          label="m_prefix_name"
          :loading="isFetchingPrefix"
          :disabled="authUser.isExecutive || disabled || isSubmiting"
        ></v-select> -->
            <!-- <input
          v-model="director.prefix_name"
          class="form-control custom__height"
          type="text"
        /> -->
            <b-form-input
              class="form-control custom__height"
              v-model="director.prefix_name"
              :state="!director.prefix_name ? false : null"
            ></b-form-input>

            <!-- <b-form-invalid-feedback id="phoneNumber-feedback">
          <span
            
          >
            กรุณากรอกอีเมล
          </span>

        </b-form-invalid-feedback> -->
          </div>
          <div class="col-lg-5 col-12 px-2 mb-2">
            <label class="font-weight-bold ft-s-14" for="box5">ชื่อ</label>
            <!-- <input
          class="form-control custom__height"
          type="text"
          :disabled="authUser.isExecutive || disabled || isSubmiting"
          v-model="director.first_name"
        /> -->
            <b-form-input
              class="form-control custom__height"
              :disabled="authUser.isExecutive || disabled || isSubmiting"
              v-model="director.first_name"
              :state="!director.first_name ? false : null"
            ></b-form-input>
          </div>
          <div class="col-lg-5 col-12 px-2 mb-2">
            <label class="font-weight-bold ft-s-14" for="box5">สกุล</label>
            <!-- <input
          class="form-control custom__height"
          type="text"
          :disabled="authUser.isExecutive || disabled || isSubmiting"
          v-model="director.last_name"
        /> -->
            <b-form-input
              class="form-control custom__height"
              :disabled="authUser.isExecutive || disabled || isSubmiting"
              v-model="director.last_name"
              :state="!director.last_name ? false : null"
            ></b-form-input>
          </div>

          <!-- <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold ft-s-14" for="box5"
          >เลขบัตรประจำตัวประชาชน (ถ้ามี)</label
        >
        <input
          class="form-control custom__height"
          type="text"
          placeholder="x-xxxx-xxxxx-xx-x"
          v-mask="'#-####-#####-##-#'"
          :disabled="authUser.isExecutive || disabled || isSubmiting"
          v-model="director.id_card"
        />
      </div> -->
        </div>
      </div>

      <div class="d-flex flex-row justify-content-between mt-4 my-2 px-2">
        <div class="d-flex flex-row align-items-baseline">
          <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
          <h4 class="font-weight-bold ft-s-18">รายชื่อครูประจำชั้น</h4>
          <small class="text-black-50 ml-2 font-weight-bold"
            >จำนวน
            {{
              formTeachingDare &&
              formTeachingDare.teacher_dares &&
              formTeachingDare.teacher_dares.length
                ? formTeachingDare.teacher_dares.length
                : 0
            }}
            คน</small
          >
        </div>
        <b-button
          v-show="!disabled"
          size="sm"
          variant="primary"
          :disabled="authUser.isExecutive || disabled || isSubmiting"
          @click.prevent="addTeacher"
          >เพิ่ม</b-button
        >
      </div>

      <div
        v-if="
          formTeachingDare &&
          formTeachingDare.teacher_dares &&
          formTeachingDare.teacher_dares.length > 0
        "
      >
        <div
          v-for="(teacher, index) in formTeachingDare.teacher_dares"
          :key="`teacher__${index}`"
          class="d-flex flex-row flex-wrap"
        >
          <div class="col-lg-2 col-12 px-2 mb-2">
            <label class="font-weight-bold ft-s-14" for="box5"
              >คำนำหน้าชื่อ</label
            >
            <!-- <input class="form-control custom__height" type="text" /> -->
            <!-- <v-select
            v-model="teacher.mPrefixId"
            :options="prefixes"
            :reduce="(prefixes) => prefixes.id"
            label="m_prefix_name"
            :loading="isFetchingPrefix"
            :disabled="authUser.isExecutive || disabled || isSubmiting"
          ></v-select> -->
            <b-form-select
              v-model="teacher.mPrefixId"
              :options="prefixes"
              :reduce="(prefixes) => prefixes.id"
              text-field="m_prefix_name"
              value-field="id"
              :loading="isFetchingPrefix"
              :disabled="authUser.isExecutive || disabled || isSubmiting"
              :state="!teacher.mPrefixId ? false : null"
            >
            </b-form-select>
          </div>
          <div class="col-lg-5 col-12 px-2 mb-2">
            <label class="font-weight-bold ft-s-14" for="box5">ชื่อ</label>
            <!-- <input
            class="form-control custom__height"
            type="text"
            :disabled="authUser.isExecutive || disabled || isSubmiting"
            v-model="teacher.first_name"
          /> -->
            <b-form-input
              class="form-control custom__height"
              :disabled="authUser.isExecutive || disabled || isSubmiting"
              v-model="teacher.first_name"
              :state="!teacher.first_name ? false : null"
            ></b-form-input>
          </div>
          <div class="col-lg-4 col-12 px-2 mb-2">
            <label class="font-weight-bold ft-s-14" for="box5">สกุล</label>
            <!-- <input
            class="form-control custom__height"
            type="text"
            :disabled="authUser.isExecutive || disabled || isSubmiting"
            v-model="teacher.last_name"
          /> -->
            <b-form-input
              class="form-control custom__height"
              :disabled="authUser.isExecutive || disabled || isSubmiting"
              v-model="teacher.last_name"
              :state="!teacher.last_name ? false : null"
            ></b-form-input>
          </div>
          <!-- <div class="col-lg-2 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box5">ประเภทบัตร</label>
          <v-select
            v-model="teacher.mCardtypeId"
            :options="cardTypes"
            :reduce="(cardTypes) => cardTypes.id"
            label="m_cardtype_name"
            :loading="isFetchingCardType"
            :disabled="authUser.isExecutive || disabled || isSubmiting"
          ></v-select>
        </div> -->

          <!-- <div class="col-lg-3 col-12 px-2 mb-2">
          <label class="font-weight-bold ft-s-14" for="box5"
            >เลขบัตรประจำตัวประชาชน (ถ้ามี)</label
          >
          <input
            class="form-control custom__height"
            type="text"
            placeholder="x-xxxx-xxxxx-xx-x"
            :disabled="authUser.isExecutive || disabled || isSubmiting"
            v-mask="'#-####-#####-##-#'"
            v-model="teacher.id_card"
          />
        </div> -->
          <div
            class="d-block col-lg-1 col-12 mb-2 pb-1 text-right"
            style="margin: auto"
          >
            <font-awesome-icon
              v-show="!authUser.isExecutive && !disabled && !isSubmiting"
              icon="window-close"
              class="fa-lg cursor-pointer"
              @click.prevent="deleteTeacher(index)"
            />
          </div>
        </div>
      </div>

      <div
        class="d-flex flex-row flex-wrap justify-content-between mt-4 my-2 px-2"
      >
        <div class="d-flex flex-row align-items-baseline">
          <font-awesome-icon icon="circle" class="fa-xs mr-2 icon_color" />
          <h4 class="font-weight-bold ft-s-18">รายชื่อนักเรียน</h4>
          <small class="text-black-50 ml-2 font-weight-bold"
            >จำนวน
            {{
              formTeachingDare &&
              formTeachingDare.student_dares &&
              formTeachingDare.student_dares.length
                ? formTeachingDare.student_dares.length
                : 0
            }}
            คน</small
          >
        </div>

        <div>
          <!-- <span>:disabled="authUser.isExecutive || disabled || isSubmiting || checkLengthStudent</span> -->
          <b-button
            v-show="!disabled"
            size="sm"
            variant="primary"
            style="margin-right: 16px"
            :disabled="authUser.isExecutive || disabled || isSubmiting"
            @click.prevent="onClickDownload"
            >ดาวน์โหลดไฟล์นำเข้า</b-button
          >
          <b-button
            v-show="!disabled"
            size="sm"
            variant="primary"
            :disabled="
              authUser.isExecutive ||
              disabled ||
              isSubmiting ||
              checkLengthStudent
            "
            @click="toggleAttachFile()"
            >นำเข้าข้อมูลนักเรียน</b-button
          >

          <input
            v-show="false"
            type="file"
            :id="`student-template`"
            name="addStudentTemplate"
            accept=".xlsx"
            ref="addStudentTemplate"
            @change="onFileChange($event)"
          />

          <b-button
            v-show="!disabled"
            size="sm"
            class="ml-3"
            variant="primary"
            :disabled="
              authUser.isExecutive ||
              disabled ||
              isSubmiting ||
              checkLengthStudent
            "
            @click.prevent="addStudent"
            >เพิ่ม</b-button
          >
        </div>
      </div>

      <div
        v-if="
          formTeachingDare &&
          formTeachingDare.student_dares &&
          formTeachingDare.student_dares.length > 0
        "
      >
        <div
          v-for="(student, index) in formTeachingDare.student_dares"
          :key="`student__${index}`"
          class="d-flex flex-row flex-wrap"
        >
          <div class="col-lg-2 col-12 px-2 mb-2">
            <label class="font-weight-bold ft-s-14" for="box5"
              >คำนำหน้าชื่อ</label
            >
            <!-- <v-select
            v-model="student.mPrefixId"
            :options="prefixes"
            :reduce="(prefixes) => prefixes.id"
            label="m_prefix_name"
            :loading="isFetchingPrefix"
            :disabled="authUser.isExecutive || disabled || isSubmiting"
          ></v-select> -->
            <b-form-select
              v-model="student.mPrefixId"
              :options="prefixes"
              :reduce="(prefixes) => prefixes.id"
              text-field="m_prefix_name"
              value-field="id"
              :loading="isFetchingPrefix"
              :disabled="authUser.isExecutive || disabled || isSubmiting"
              :state="!student.mPrefixId ? false : null"
            >
            </b-form-select>
            <!-- <input class="form-control custom__height" type="text" /> -->
          </div>
          <div class="col-lg-2 col-12 px-2 mb-2">
            <label class="font-weight-bold ft-s-14" for="box5">ชื่อ</label>
            <!-- <input
            class="form-control custom__height"
            type="text"
            :disabled="authUser.isExecutive || disabled || isSubmiting"
            v-model="student.first_name"
          /> -->
            <b-form-input
              class="form-control custom__height"
              :disabled="authUser.isExecutive || disabled || isSubmiting"
              v-model="student.first_name"
              :state="!student.first_name ? false : null"
            ></b-form-input>
          </div>
          <div class="col-lg-2 col-12 px-2 mb-2">
            <label class="font-weight-bold ft-s-14" for="box5">สกุล</label>
            <!-- <input
            class="form-control custom__height"
            type="text"
            :disabled="authUser.isExecutive || disabled || isSubmiting"
            v-model="student.last_name"
          /> -->
            <b-form-input
              class="form-control custom__height"
              :disabled="authUser.isExecutive || disabled || isSubmiting"
              v-model="student.last_name"
              :state="!student.last_name ? false : null"
            ></b-form-input>
          </div>
          <div class="col-lg-2 col-12 px-2 mb-2">
            <label class="font-weight-bold ft-s-14" for="box5"
              >ประเภทบัตร</label
            >
            <!-- <v-select
            v-model="student.mCardtypeId"
            :options="cardTypes"
            :reduce="(cardTypes) => cardTypes.id"
            label="m_cardtype_name"
            :loading="isFetchingCardType"
            :disabled="authUser.isExecutive || disabled || isSubmiting"
          ></v-select> -->
            <b-form-select
              v-model="student.mCardtypeId"
              :options="cardTypes"
              :reduce="(cardTypes) => cardTypes.id"
              text-field="m_cardtype_name"
              value-field="id"
              :loading="isFetchingPrefix"
              :disabled="authUser.isExecutive || disabled || isSubmiting"
              :state="!student.mCardtypeId ? false : null"
            >
            </b-form-select>
            <!-- <input class="form-control custom__height" type="text" /> -->
          </div>
          <div class="col-lg-3 col-12 px-2 mb-2">
            <label class="font-weight-bold ft-s-14" for="box5"
              >เลขบัตรประจำตัวประชาชน</label
            >
            <b-form-input
              v-if="isCardType(student.mCardtypeId, 'ต่างชาติ')"
              class="form-control custom__height"
              :disabled="authUser.isExecutive || disabled || isSubmiting"
              v-model="student.id_card"
              :state="!student.id_card ? false : null"
            ></b-form-input>
            <b-form-input
              v-else
              class="form-control custom__height"
              :disabled="authUser.isExecutive || disabled || isSubmiting"
              v-model="student.id_card"
              :state="!student.id_card ? false : student.invalid ? false : null"
              placeholder="x-xxxx-xxxxx-xx-x"
              v-mask="'#-####-#####-##-#'"
            ></b-form-input>
            <!-- <input
            v-if="isCardType(student.mCardtypeId, 'ต่างชาติ')"
            class="form-control custom__height"
            type="text"
            :disabled="authUser.isExecutive || disabled || isSubmiting"
            v-model="student.id_card"
          />
          <input
            v-else
            class="form-control custom__height"
            type="text"
            placeholder="x-xxxx-xxxxx-xx-x"
            :disabled="authUser.isExecutive || disabled || isSubmiting"
            v-mask="'#-####-#####-##-#'"
            v-model="student.id_card"
          /> -->
          </div>
          <div
            class="d-block col-lg-1 col-12 mb-2 pb-1 text-right"
            style="margin: auto"
          >
            <font-awesome-icon
              v-show="!disabled && !isSubmiting"
              icon="window-close"
              class="fa-lg cursor-pointer"
              @click.prevent="deleteStudent(index)"
            />
          </div>
        </div>
      </div>
      <!-- <teaching-dare-form
      v-model="formTeachingDare"
      :edit-data="editData"
      :disabled="authUser.isExecutive || disabled || isSubmiting"
      :isClass="true"
    >
      <template #custom-body>
        
      </template>
    </teaching-dare-form> -->

      <div ref="classRef"></div>
      <lesson-form
        v-model="formLesson"
        :disabled="authUser.isExecutive || disabled || isSubmiting"
        :disabledTime="!authUser.isSuperAdmin && isPlanSuccess"
        isClass
      >
      </lesson-form>

      <div class="my-3" v-if="config_actual_img.is_open === true">
        <div class="w-100 font-weight-bold ft-s-20" for="box9">
          รูปหลักฐานการสอน (ไม่เกิน 2 รูป)
        </div>

        <div class="d-flex flex-row my-3">
          <!-- <div
            class="image-preview d-flex flex-row-warp"
            style="flex-wrap: wrap"
            v-if="
              editData.teaching_actual_imgs && 
              editData.teaching_actual_imgs.length > 0
            "
          >
            <div
              v-for="(imageData, index) in editData.teaching_actual_imgs"
              :key="`image__${index}`"
              class="box-preview d-flex flex-row"
            >
              <div 
                class="wrap-image mr-3"
                v-if="!old_teaching_imgs.find((key) => key === imageData.key)"
              >
                <button
                  class="close-button"
                  @click="deleteImage(index, 'edit')"
                >
                  <font-awesome-icon
                    icon="times"
                    class="mx-auto"
                  />
                </button>

                <img
                  class="img-thumbnail w-100 h-100"
                  :src="imageData.url !== '' ? imageData.url : imageData.raw"
                />
              </div>
            </div>
          </div> -->

          <div
            class="image-preview d-flex flex-row-warp"
            style="flex-wrap: wrap"
            v-if="teaching_actual_imgs && teaching_actual_imgs.length > 0"
          >
            <div
              v-for="(imageData, index) in teaching_actual_imgs"
              :key="`image__${index}`"
              class="box-preview d-flex flex-row"
            >
              <div class="wrap-image mr-3">
                <button class="close-button" @click="deleteImage(index, 'add')">
                  <font-awesome-icon icon="times" class="mx-auto" />
                </button>

                <img
                  class="img-thumbnail w-100 h-100"
                  :src="imageData.url !== '' ? imageData.url : imageData.raw"
                />
              </div>
            </div>
          </div>

          <button
            class="btn-select-file ft-s-14"
            :disabled="disabled"
            v-if="
              // !editData.teaching_actual_imgs || (
              //   editData.teaching_actual_imgs && (
              //     editData.teaching_actual_imgs.filter(
              //       ({ key }) => !old_teaching_imgs.includes(key)
              //     ).length +
              //     teaching_actual_imgs.length
              //   ) < maxFiles)
              teaching_actual_imgs.length < maxFiles
            "
            @click="toggleAttachImage()"
          >
            <font-awesome-icon icon="plus" class="mx-auto" />
            อัพโหลด
          </button>

          <input
            v-show="false"
            type="file"
            :id="`lesson-image-file`"
            name="addLessonPlanImg"
            accept="image/*"
            multiple
            ref="addLessonPlanImg"
            @change="previewImages($event)"
          />
        </div>
        <div class="d-flex flex-row flex-wrap">
          <small class="text-black-50 ml-2 font-weight-bold ft-s-16">
            * รูปที่ 1 รูปการสอน เห็นครูตำรวจแดร์ นักเรียนแดร์ สื่อการสอนแดร์
          </small>
        </div>
        <div class="d-flex flex-row flex-wrap">
          <small class="text-black-50 ml-2 font-weight-bold ft-s-16">
            * รูปที่ 2 ภาพพิธีปิดและมอบใบประกาศนักเรียนแดร์
          </small>
        </div>
        <div class="d-flex flex-row flex-wrap">
          <small class="text-black-50 ml-2 font-weight-bold ft-s-16">
            * JPG, PNG ขนาดไม่เกิน 15MB (ขนาดแนะนำ {{ imageWidth }} x
            {{ imageHeight }} px)
          </small>

          <small class="text-danger ml-2 font-weight-bold ft-s-16"
            >*กรณีที่รูปผิด ท่านสามารถลบรูปและเลือกรูปใหม่ และกดบันทึก</small
          >
        </div>
      </div>
    </div>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        {{ disabled ? "ปิด" : "ยกเลิก" }}
      </b-button>
      <b-button
        v-show="!disabled"
        size="sm"
        variant="primary"
        :disabled="authUser.isExecutive || isSubmiting"
        @click="saveClass()"
      >
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>บันทึก</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Compressor from "compressorjs";
import formatDateMixin from "../../mixins/formatDateMixin";
import lessonMixin from "../../mixins/lessonMixin";
import validatorMixin from "../../mixins/validatorMixin";
import TeachingDareForm from "../form/TeachingDare";
import LessonForm from "../form/Lesson";
import FilterMaster from "../form/FilterMaster";
import FilterProvince from "../form/FilterProvince";
// import vSelect from "vue-select";
import { Auth, MPrefix, MCardType, TeachingDare } from "../../models";
import { map } from "lodash";
import axios from "axios";

import readXlsxFile from "read-excel-file";

export default {
  mixins: [formatDateMixin, lessonMixin, validatorMixin],

  name: "CreateOrUpdateClass",

  components: {
    TeachingDareForm,
    LessonForm,
    // vSelect,
    FilterMaster,
    FilterProvince,
  },

  props: {
    show: Boolean,
    classData: Object,
    editData: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    imageWidth: {
      type: Number,
      default: 1920,
    },

    imageHeight: {
      type: Number,
      default: 1080,
    },
  },

  model: {
    prop: "show",
    event: "update",
  },

  data() {
    return {
      filterMaster: {
        mHeadquarterId: this.editData.mHeadquarterId || null,
        mDivisionId: this.editData.mDivisionId || null,
        mStationId: this.editData.mStationId || null,
        // mSchoolId: this.editData.mSchoolId || null,
      },
      filterProvince: {
        mProvinceId: this.editData.mProvinceId || null,
        mAmphurId: this.editData.mAmphurId || null,
        mSchoolId: this.editData.mSchoolId || null,
      },
      formTeachingDare: {
        actual_student: null,
        // mStationId: "",
        // mSchoolId: "",
        // mClassId: "",
        fiscal_year: "",
        director_dares: [
          {
            first_name: "",
            last_name: "",
            id_card: "",
            phone_number: "",
            email: "",
            mPrefixId: null,
            mCardtypeId: null,
            prefix_name: "",
          },
        ],
        teacher_dares: [
          // {
          //   first_name: "",
          //   last_name: "",
          //   id_card: "",
          //   phone_number: "",
          //   email: "",
          //   mPrefixId: null,
          //   mCardtypeId: null,
          // },
        ],
        student_dares: [
          // {
          //   first_name: "",
          //   last_name: "",
          //   id_card: "",
          //   phone_number: "",
          //   email: "",
          //   mPrefixId: null,
          //   mCardtypeId: null,
          // },
        ],
      },
      formLesson: this.$_lessonMixin_makeLesson(),
      teaching_actual_imgs: [],
      maxFiles: 2,
      old_teaching_imgs: [], // for delete image keys
      isFetchingPrefix: false,
      isFetchingCardType: false,
      isSubmiting: false,
      config_actual_img: {},
    };
  },
  // this.teaching_actual_imgs = this.editData.teaching_actual_imgs || [];
  watch: {
    "editData.teaching_plans": "updateFormLesson",
    editData: function (newQuestion) {
      // console.log("newQuestion : ", newQuestion);
      this.teaching_actual_imgs = newQuestion.teaching_actual_imgs;
      if (newQuestion?.config_actual_img !== undefined) {
        this.config_actual_img = newQuestion?.config_actual_img;
      } else {
        this.config_actual_img = { is_open: false };
      }
    },
  },

  computed: {
    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },

    prefixes() {
      return MPrefix.all();
    },

    cardTypes() {
      return MCardType.all();
    },

    cardTypeKeyByName() {
      return this.cardTypes.reduce((acc, cardType) => {
        this.$set(acc, cardType.id, cardType.m_cardtype_name);
        return acc;
      }, {});
    },

    authUser() {
      return Auth.user();
    },

    checkLengthStudent() {
      return (
        this.formTeachingDare.student_dares.length >=
        this.formTeachingDare.plan_student
      );
    },

    isPlanSuccess() {
      return this.editData.isPlan === "สำเร็จ";
    },
  },

  methods: {
    async init() {
      try {
        this.isFetchingPrefix = true;
        this.isFetchingCardType = true;
        await MPrefix.api().findAll();
        await MCardType.api().findAll();
      } catch (error) {
        console.error(error);
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลคำนำหน้าหรือข้อมูลบัตรได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetchingPrefix = false;
        this.isFetchingCardType = false;
      }
    },

    scrollToSection(ref) {
      this.$refs[ref].scrollIntoView({ behavior: "smooth" });
    },

    onClickDownload() {
      window.open(
        "https://planet-x-technology.sgp1.digitaloceanspaces.com/dare/template-student/student_template%20(1).xlsx",
        "blank"
      );
    },

    toggleAttachFile() {
      this.$nextTick(() => {
        const { addStudentTemplate } = this.$refs;
        addStudentTemplate.click();
      });
    },

    async onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        // console.log("rows:", rows);
        let index = 0;
        for (const excelData of rows) {
          // console.log(excelData);
          if (index != 0) {
            this.formTeachingDare.student_dares.push({
              first_name: excelData[1],
              last_name: excelData[2],
              id_card: excelData[4],
              phone_number: "",
              email: "",
              mPrefixId:
                excelData[0] == "นาย"
                  ? 1
                  : excelData[0] == "นาง"
                  ? 2
                  : excelData[0] == "นางสาว"
                  ? 4
                  : excelData[0] == "เด็กชาย"
                  ? 5
                  : excelData[0] == "เด็กหญิง"
                  ? 6
                  : "",
              mCardtypeId:
                excelData[3] == "บัตร ปชช."
                  ? 1
                  : excelData[3] == "ต่างชาติ"
                  ? 2
                  : "",
            });
          }
          index++;
        }
      });
    },

    isCardType(checkId, checkName) {
      return this.cardTypeKeyByName[checkId] === checkName;
    },

    chkDigitPid(p_iPID) {
      var total = 0;
      var iPID;
      var chk;
      var Validchk;
      iPID = p_iPID.replace(/-/g, "");
      Validchk = iPID.substr(12, 1);
      var j = 0;
      var pidcut;
      for (var n = 0; n < 12; n++) {
        pidcut = parseInt(iPID.substr(j, 1));
        total = total + pidcut * (13 - n);
        j++;
      }

      chk = 11 - (total % 11);

      if (chk == 10) {
        chk = 0;
      } else if (chk == 11) {
        chk = 1;
      }
      if (chk == Validchk) {
        // alert("ระบุหมายเลขประจำตัวประชาชนถูกต้อง");
        return true;
      } else {
        // alert("ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง");
        return false;
      }
    },
    updateFormLesson(teachingPlans) {
      if (teachingPlans && teachingPlans.length) {
        this.formLesson = this.$_lessonMixin_makeLesson([...teachingPlans]);
      } else {
        this.formLesson = this.$_lessonMixin_makeLesson([]);
      }
    },

    checkNullArr(array, key = "") {
      if (key == "lesson") {
        for (let index = 0; index < array.length; index++) {
          const {
            lesson_name = null,
            date = null,
            time = null,
            note = null,
          } = array[index];
          if (!lesson_name || !date || !time || !note) return true;
        }
        return false;
      } else if (key == "director") {
        for (let index = 0; index < array.length; index++) {
          const {
            first_name = null,
            last_name = null,
            prefix_name = null,
          } = array[index];
          if (!first_name || !last_name || !prefix_name) return true;
        }
        return false;
      } else if (key == "teacher") {
        for (let index = 0; index < array.length; index++) {
          const {
            first_name = null,
            last_name = null,
            mPrefixId = null,
          } = array[index];
          if (!first_name || !last_name || !mPrefixId) return true;
        }
        return false;
      } else {
        for (let index = 0; index < array.length; index++) {
          const {
            first_name = null,
            last_name = null,
            mPrefixId = null,
            mCardtypeId = null,
            id_card = null,
          } = array[index];

          if (
            !first_name ||
            !last_name ||
            !mPrefixId ||
            !mCardtypeId ||
            !id_card
          )
            return true;
        }
        return false;
      }
    },

    async previewImages(event) {
      let { maxFiles, teaching_actual_imgs } = this;

      if (teaching_actual_imgs.length < this.maxFiles) {
        maxFiles -= teaching_actual_imgs.length;
      }

      if (maxFiles <= 0) {
        return;
      }

      const self = this;

      const { imageWidth, imageHeight } = this;

      var input = event.target;

      const maxAllowedSize = 15 * 1024 * 1024;

      this.isProcessing = true;

      let fileCounter = 0;

      Array.from(input.files)
        .slice(0, maxFiles)
        .forEach((file) => {
          if (file.size <= maxAllowedSize) {
            fileCounter++;

            new Compressor(file, {
              quality: 0.8,

              maxWidth: imageWidth,

              maxHeight: imageHeight,

              // The compression process is asynchronous,
              // which means you have to access the `result` in the `success` hook function.
              success(result) {
                fileCounter -= 1;

                var reader = new FileReader();

                reader.onload = async (e) => {
                  self.teaching_actual_imgs.push({
                    key: "",
                    url: "",
                    file: result,
                    raw: e.target.result,
                  });

                  if (fileCounter === 0) {
                    self.isProcessing = false;
                  }
                };

                reader.readAsDataURL(result);
              },
              error(err) {
                console.log(err.message);
                this.$toast.error(
                  "ไม่สามารถอัพโหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง"
                );
              },
            });

            // var reader = new FileReader();

            // reader.onload = async (e) => {
            //   this.formLessons[index].teaching_actual_imgs.push({
            //     file,
            //     raw: e.target.result,
            //   });
            // };

            // reader.readAsDataURL(file);
          } else {
            this.$toast.error(
              "ไม่สามารถอัพโหลดไฟล์ขนาดใหญ่เกิน 15 MB ได้ กรุณาลองใหม่อีกครั้ง"
            );
          }
        });

      if (fileCounter === 0) {
        this.isProcessing = false;
      }

      input.value = "";

      // if (
      //   input.files &&
      //   input.files[0] &&
      //   input.files[0].size > maxAllowedSize
      // ) {
      //   input.value = "";
      // } else {
      //   var reader = new FileReader();
      //   reader.onload = async (e) => {
      //     this.formLessons[index].teaching_actual_imgs.push(e.target.result);
      //   };
      //   reader.readAsDataURL(input.files[0]);
      // }
    },

    // eslint-disable-next-line no-unused-vars
    deleteImage(index, stage) {
      // if (stage === "edit") {
      //   this.old_teaching_imgs.push(
      //     this.editData.teaching_actual_imgs[index].key
      //   );
      // } else {
      //   this.$delete(this.teaching_actual_imgs, index);
      // }
      if (this.teaching_actual_imgs.length > 0) {
        this.old_teaching_imgs.push(this.teaching_actual_imgs[index].key);
        this.$delete(this.teaching_actual_imgs, index);
      }
    },

    toggleAttachImage() {
      if (this.teaching_actual_imgs.length >= this.maxFiles) {
        return this.$toast.error(
          `ไม่สามารถแนบหลักฐานการสอนได้เกิน ${this.maxFiles} รูป`
        );
      }

      this.$nextTick(() => {
        const { addLessonPlanImg } = this.$refs;
        addLessonPlanImg.click();
      });
    },

    async saveClass() {
      const { mStationId, mClassId } = this.filterMaster,
        { mSchoolId } = this.filterProvince;

      const {
        actual_student,
        plan_student,
        room,
        director_dares,
        student_dares,
        teacher_dares,
      } = this.formTeachingDare;

      // if (
      //   !mStationId ||
      //   !mSchoolId ||
      //   !mClassId ||
      //   !fiscal_year
      // !actual_student ||
      // director_dares.length == 0 ||
      // this.checkNullArr(director_dares, "director") ||
      // student_dares.length == 0 ||
      // this.checkNullArr(student_dares) ||
      // teacher_dares.length == 0 ||
      // this.checkNullArr(teacher_dares)
      // this.formLesson.length == 0 ||
      // this.checkNullArr(this.formLesson, "lesson")
      // ) {
      //   return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      // }

      // if (
      //   this.formLesson.length > 0 &&
      //   this.$_validatorMixin_checkNullArr(this.formLesson, [
      //     "lesson_name",
      //     "date",
      //     "time",
      //     "content",
      //   ])
      // ) {
      //   return this.$toast.error(`กรุณากรอกวันและเวลาในแผนการสอนให้เรียบร้อย`);
      // }

      if (director_dares && director_dares.length) {
        if (this.checkNullArr(director_dares, "director")) {
          return this.$toast.error(`กรุณากรอกข้อมูลผู้อำนวยการให้ครบถ้วน`);
        }

        for (let index = 0; index < director_dares.length; index++) {
          const id_card = director_dares[index].id_card;

          // if (
          //   id_card.length == 0 &&
          //   director_dares[index].mCardtypeId &&
          //   director_dares[index].mCardtypeId == 2
          // ) {
          //   director_dares[index].id_card = "";
          // } else if (!this.chkDigitPid(id_card)) {
          //   return this.$toast.error(
          //     `เลขประจำตัวประชาชนของผู้อำนวยการในลำดับที่${index +
          //       1} ไม่ถูกต้อง`
          //   );
          // }

          if (id_card.length) {
            if (!this.chkDigitPid(id_card)) {
              return this.$toast.error(
                `เลขประจำตัวประชาชนของผู้อำนวยการในลำดับที่${
                  index + 1
                } ไม่ถูกต้อง`
              );
            }
          }

          // director_dares[index].id_card = id_card.replace(/-/g, "");
        }
      }

      if (teacher_dares && teacher_dares.length) {
        if (this.checkNullArr(teacher_dares, "teacher")) {
          return this.$toast.error(`กรุณากรอกข้อมูลครูประจำชั้นให้ครบถ้วน`);
        }

        for (let index = 0; index < teacher_dares.length; index++) {
          const id_card = teacher_dares[index].id_card;

          // if (
          //   id_card.length == 0 &&
          //   teacher_dares[index].mCardtypeId &&
          //   teacher_dares[index].mCardtypeId == 2
          // ) {
          //   teacher_dares[index].id_card = "";
          // } else if (!this.chkDigitPid(id_card)) {
          //   return this.$toast.error(
          //     `เลขประจำตัวประชาชนของครูประจำชั้นในลำดับที่${index +
          //       1} ไม่ถูกต้อง`
          //   );
          // }

          if (id_card.length) {
            if (!this.chkDigitPid(id_card)) {
              return this.$toast.error(
                `เลขประจำตัวประชาชนของครูประจำชั้นในลำดับที่${
                  index + 1
                } ไม่ถูกต้อง`
              );
            }
          }

          // teacher_dares[index].id_card = id_card.replace(/-/g, "");
        }
      }

      if (student_dares && student_dares.length) {
        if (this.checkNullArr(student_dares)) {
          return this.$toast.error(`กรุณากรอกข้อมูลนักเรียนให้ครบถ้วน`);
        }

        for (let index = 0; index < student_dares.length; index++) {
          const id_card = student_dares[index].id_card;

          // if (
          //   id_card.length == 0 &&
          //   student_dares[index].mCardtypeId &&
          //   this.isCardType(student_dares[index].mCardtypeId, "ต่างชาติ")
          // ) {
          //   student_dares[index].id_card = "";
          // } else if (!this.chkDigitPid(id_card)) {
          //   return this.$toast.error(
          //     `เลขประจำตัวประชาชนของนักเรียนในลำดับที่${index + 1} ไม่ถูกต้อง`
          //   );
          // }

          if (id_card.length) {
            if (
              this.isCardType(student_dares[index].mCardtypeId, "บัตร ปชช.") &&
              !this.chkDigitPid(id_card)
            ) {
              student_dares[index].invalid = true;
              return this.$toast.error(
                `เลขประจำตัวประชาชนของนักเรียนในลำดับที่${index + 1} ไม่ถูกต้อง`
              );
            }
          }
          // student_dares[index].id_card = id_card.replace(/-/g, "");
        }
      }

      let director_id_cards = map(director_dares, "id_card").map((id_card) =>
        id_card.replace(/-/g, "")
      );
      let teacher_id_cards = map(teacher_dares, "id_card").map((id_card) =>
        id_card.replace(/-/g, "")
      );
      let student_id_cards = map(student_dares, "id_card").map((id_card) => {
        return id_card.replace(/-/g, "");
      });

      const all_id_cards = [
        ...director_id_cards,
        ...teacher_id_cards,
        ...student_id_cards,
      ];

      let isDuplicateCardIds = all_id_cards.filter(
        (item, index) => all_id_cards.indexOf(item) != index
      );

      if (isDuplicateCardIds && isDuplicateCardIds.length > 0) {
        let nullArr = [""];
        isDuplicateCardIds = isDuplicateCardIds.filter(
          (item) => !nullArr.includes(item)
        );
        if (isDuplicateCardIds && isDuplicateCardIds.length > 0) {
          return this.$toast.error(`เลขบัตรประจำตัวประชาชนห้ามซ้ำกัน`);
        }
      }

      if (this.formLesson.length > 0) {
        let checkTime;
        for (let index = 0; index < this.formLesson.length; index++) {
          const element = this.formLesson[index].time;
          if (
            this.$_validatorMixin_checkDateTime(element) === "format_incorrect"
          ) {
            this.$toast.error(`[ บทที่${index + 1}. ] ฟอร์แมตเวลาไม่ถูกต้อง`);
            checkTime = false;
            break;
          } else if (
            this.$_validatorMixin_checkDateTime(element) === "equal_time"
          ) {
            this.$toast.error(
              `[ บทที่${index + 1}. ] เวลาเริ่มต้นและเวลาสิ้นสุด ต้องไม่เท่ากัน`
            );
            checkTime = false;
            break;
          } else if (
            this.$_validatorMixin_checkDateTime(element) === "wrong_time"
          ) {
            this.$toast.error(
              `[ บทที่${index + 1}. ] เวลาเริ่มต้นต้องน้อยกว่าเวลาสิ้นสุด`
            );
            checkTime = false;
            break;
          }
          checkTime = true;
        }
        if (!checkTime) return;
      }

      // for (let index = 0; index < this.formLesson.length; index++) {
      //   this.formLesson[index].lesson_number = `${index + 1}`;
      // }

      let { formLesson = [], teaching_actual_imgs, old_teaching_imgs } = this;

      this.isSubmiting = true;

      // formLesson = await this.uploadTeachingActualImagesFromLesson(
      //   this.editData.id,
      //   formLesson
      // );

      teaching_actual_imgs = await this.uploadImages();

      const body = {
        actual_student,
        plan_student,
        room,
        mStationId,
        mSchoolId,
        mClassId,
        director_dares,
        student_dares,
        teacher_dares,
        teaching_plans: formLesson,
        teaching_actual_imgs,
        old_teaching_imgs,
        isPlan:
          this.editData.isPlan === "สำเร็จ" ? "ทำแผนการสอน" : "ไม่ทำแผนการสอน",
      };

      // this.isSubmiting = false;

      // console.log('----- body -----', body)

      let promise = await TeachingDare.api().updatePlan(this.editData.id, body);

      this.isSubmiting = false;

      const { data } = promise.response;

      if (data && data.response_status == "SUCCESS") {
        this.$toast.success("อัพเดตข้อมูลการสอนเรียบร้อยแล้ว");
        this.$bvModal.hide("create-or-update-class-modal");
        this.$emit("create:success");
      } else {
        if (data.message) {
          this.$toast.error(data.message);
        } else {
          this.$toast.error("อัพเดตข้อมูลการสอนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
        }
      }
    },

    downloadTemplate() {
      window.open(
        "https://แดร์ประเทศไทย.com/public_file/ไฟล์นำเข้าข้อมูลนักเรียน.xlsx",
        "_blank"
      );
    },

    async uploadTeachingActualImagesFromLesson(teachingDareId, lessons) {
      const updatedLessons = await Promise.all(
        lessons.map(async (lesson) => {
          const newImages = lesson.teaching_actual_imgs.filter(
            (image) => image.raw !== undefined
          );

          const oldImages = lesson.teaching_actual_imgs.filter(
            (image) => image.id !== undefined
          );

          let teachingActualImgs = [];

          if (oldImages && oldImages.length) {
            teachingActualImgs = [...map(oldImages, "teaching_actual_img")];
          }

          // let uploadedUrls = await Promise.all(
          //   newImages.map(async (newImage) => {
          //     const presignPromise = await TeachingDare.api().getUploadUrl({
          //       key: `teaching-dare/${teachingDareId}/lesson/${lesson.id}/teaching-actual-images/${newImage.file.name}`,
          //       contentType: newImage.file.type,
          //     });

          //     const { url, fullUrl } = presignPromise.response.data;

          //     if (url) {
          //       await axios.put(url, newImage.file);
          //     }

          //     return fullUrl;
          //   })
          // );

          let uploadedUrls = [];

          for (
            let newImageIndex = 0;
            newImageIndex < newImages.length;
            newImageIndex++
          ) {
            const newImage = newImages[newImageIndex];

            // const presignPromise = await TeachingDare.api().getUploadUrl({
            //   key: `teaching-dare/${teachingDareId}/lesson/${lesson.lesson_number}/teaching-actual-images/${newImage.file.name}`,
            //   contentType: newImage.file.type,
            // });

            // const { url, fullUrl } = presignPromise.response.data;

            // if (url) {
            //   await axios.put(url, newImage.file);
            // }

            let fullUrl;

            let formData = new FormData();

            formData.append("files", newImage.file);

            let uploadResponse = await axios.post(
              "https://service.xn--l3cgao1bcb5b5d8hf8b6f.com/files/uploadFormData",
              formData
            );
            // console.log(uploadResponse.data.data);
            fullUrl = uploadResponse.data.data.url;

            uploadedUrls.push(fullUrl);
          }

          if (uploadedUrls && uploadedUrls.length) {
            teachingActualImgs = [...teachingActualImgs, ...uploadedUrls];
          }

          return {
            ...lesson,
            teaching_actual_imgs: teachingActualImgs,
          };
        })
      );

      return updatedLessons;
    },

    // async uploadTeachingActualImages() {
    //   const newImages = this.teaching_actual_imgs.filter(
    //     (image) => image.raw !== undefined
    //   );

    //   const oldImages = this.teaching_actual_imgs.filter(
    //     (image) => image.id !== undefined
    //   );

    //   let teachingActualImgs = [];

    //   if (oldImages && oldImages.length) {
    //     teachingActualImgs = [...map(oldImages, "teaching_actual_img")];
    //   }

    //   let uploadedUrls = [];

    //   for (
    //     let newImageIndex = 0;
    //     newImageIndex < newImages.length;
    //     newImageIndex++
    //   ) {
    //     const newImage = newImages[newImageIndex];

    //     let fullUrl;

    //     let formData = new FormData();

    //     formData.append("files", newImage.file);

    //     // let uploadResponse = await TeachingDare.api().getUploadUrl(
    //     //   { key: `teaching_actual_img-${newImageIndex}` },
    //     //   formData
    //     // );

    //     let uploadResponse = await axios.post(
    //       "https://service.xn--l3cgao1bcb5b5d8hf8b6f.com/files/uploadFormData",
    //       formData
    //     );

    //     console.log(uploadResponse.data.data);
    //     fullUrl = uploadResponse.data.data.url;

    //     uploadedUrls.push(fullUrl);
    //   }

    //   if (uploadedUrls && uploadedUrls.length) {
    //     teachingActualImgs = [...teachingActualImgs, ...uploadedUrls];
    //   }

    //   return teachingActualImgs;
    // },

    async uploadImages() {
      try {
        const newImages = this.teaching_actual_imgs.filter(
          (image) => image.raw !== undefined
        );

        // const oldImages = this.editData.teaching_actual_imgs.filter(
        //   ({ key }) => !this.old_teaching_imgs.includes(key)
        // );
        const oldImages = this.teaching_actual_imgs.filter(
          (image) => image.url !== ""
        );

        let teachingActualImgs = [];

        if (oldImages && oldImages.length) {
          teachingActualImgs = [
            ...teachingActualImgs,
            ...oldImages.map(({ key }) => key),
          ];
        }

        let uploadedUrls = [];

        for (
          let newImageIndex = 0;
          newImageIndex < newImages.length;
          newImageIndex++
        ) {
          const newImage = newImages[newImageIndex];

          let uploadPromise = await TeachingDare.api().getUploadUrl({
            key: `teaching_actual_img_${newImageIndex}`,
            contentType: newImage.file.type,
            imageType: `.${newImage.file.type.split("/").pop()}`,
          });

          if (uploadPromise && uploadPromise.response) {
            const { url, key } = uploadPromise.response.data; // key, url, status

            if (url) {
              await axios.put(url, newImage.file);
            }
            uploadedUrls.push(key);
          }
        }

        // console.log("uploadedUrls : ", uploadedUrls);

        if (uploadedUrls && uploadedUrls.length) {
          teachingActualImgs = [...teachingActualImgs, ...uploadedUrls];
        }

        return teachingActualImgs;
      } catch (error) {
        console.error(error);
      }
    },

    addTeacher() {
      this.formTeachingDare.teacher_dares.push({
        first_name: "",
        last_name: "",
        id_card: "",
        phone_number: "",
        email: "",
        mPrefixId: null,
        mCardtypeId: null,
      });
    },

    addStudent() {
      this.formTeachingDare.student_dares.push({
        first_name: "",
        last_name: "",
        id_card: "",
        phone_number: "",
        email: "",
        mPrefixId: null,
        mCardtypeId: null,
      });
    },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.filterMaster = {
        mHeadquarterId: null,
        mDivisionId: null,
        mStationId: null,
        mSchoolId: null,
      };

      this.formTeachingDare = {
        actual_student: null,
        fiscal_year: "",
        director_dares: [
          {
            first_name: "",
            last_name: "",
            id_card: "",
            phone_number: "",
            email: "",
            mPrefixId: null,
            mCardtypeId: null,
          },
        ],
        teacher_dares: [
          // {
          //   first_name: "",
          //   last_name: "",
          //   id_card: "",
          //   phone_number: "",
          //   email: "",
          //   mPrefixId: null,
          //   mCardtypeId: null,
          // },
        ],
        student_dares: [
          // {
          //   first_name: "",
          //   last_name: "",
          //   id_card: "",
          //   phone_number: "",
          //   email: "",
          //   mPrefixId: null,
          //   mCardtypeId: null,
          // },
        ],
      };
      this.formLesson = this.$_lessonMixin_makeLesson();
      (this.teaching_actual_imgs = []),
        (this.old_teaching_imgs = []),
        this.$emit("update:editData", {});
    },
    deleteStudent(index) {
      this.formTeachingDare.student_dares.splice(index, 1);
    },
    deleteTeacher(index) {
      this.formTeachingDare.teacher_dares.splice(index, 1);
    },
    // async fetchConfig() {
    //   try {
    //     const promise = await ConfigActualImg.api().findAll({ save: false });

    //     let { data = []} = promise.response.data;
    //     this.config_actual_img = data;
    //   } catch (error) {
    //     console.log(error);
    //     this.$toast.error("ไม่สามารถดึงข้อมูลประกาศได้ กรุณาลองใหม่อีกครั้ง");
    //   } finally {
    //     this.isFetching = false;
    //   }
    // }
  },
  mounted() {
    this.init();
  },
  // created() {
  //   this.fetchConfig();
  // }
};
</script>

<style lang="scss" scoped>
.menu-list {
  width: calc(100% - 2rem);
  border-bottom: 1px solid #d9d9d9;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #ffffff;
  padding-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 20px;
}

.menu-item {
  min-width: 130px;
  text-align: center;
  border: none;
  background-color: transparent;
  color: #3d6b99;

  &:focus {
    background-color: #6187ad40;
    border: none;
    outline: none;
  }
}
.wrap-body {
  padding: 1rem;
  height: calc(100% - 60px);
  overflow-y: auto;
}
.wrap-image {
  position: relative;
  width: 150px;
  height: 150px;

  > img {
    object-fit: cover;
  }
}
.close-button {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -8px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  background-color: #ffffff;
  border: 1px solid #3d6b99;
  border-radius: 50%;
  padding: 3px;

  &:focus {
    outline: none;
  }
}

.btn-select-file {
  width: 104px;
  height: 104px;
  border: 1px dashed #d9d9d9;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:focus {
    outline: 1px dashed #d9d9d9;
  }
}
</style>
